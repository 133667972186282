var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-sidebar", {
        attrs: {
          id: "sidebar-task-handler",
          "sidebar-class": "sidebar-lg",
          visible: _vm.isTaskHandlerSidebarActive,
          "bg-variant": "white",
          shadow: "",
          backdrop: "",
          "no-header": "",
          right: ""
        },
        on: {
          change: function(val) {
            return _vm.$emit("update:is-task-handler-sidebar-active", val)
          },
          hidden: _vm.clearForm
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hide = ref.hide
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
                  },
                  [
                    _vm.taskLocal.id
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              size: "sm",
                              variant: _vm.taskLocal.isCompleted
                                ? "outline-success"
                                : "outline-secondary"
                            },
                            on: {
                              click: function($event) {
                                _vm.taskLocal.isCompleted = !_vm.taskLocal
                                  .isCompleted
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.taskLocal.isCompleted
                                    ? "Completed"
                                    : "Mark Complete"
                                ) +
                                " "
                            )
                          ]
                        )
                      : _c("h5", { staticClass: "mb-0" }, [
                          _vm._v(" Add Task ")
                        ]),
                    _c(
                      "div",
                      [
                        _c("feather-icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.taskLocal.id,
                              expression: "taskLocal.id"
                            }
                          ],
                          staticClass: "cursor-pointer",
                          attrs: { icon: "TrashIcon" },
                          on: {
                            click: function($event) {
                              _vm.$emit("remove-task")
                              hide()
                            }
                          }
                        }),
                        _c("feather-icon", {
                          staticClass: "ml-1 cursor-pointer",
                          class: { "text-warning": _vm.taskLocal.isImportant },
                          attrs: { icon: "StarIcon", size: "16" },
                          on: {
                            click: function($event) {
                              _vm.taskLocal.isImportant = !_vm.taskLocal
                                .isImportant
                            }
                          }
                        }),
                        _c("feather-icon", {
                          staticClass: "ml-1 cursor-pointer",
                          attrs: { icon: "XIcon", size: "16" },
                          on: { click: hide }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("validation-observer", {
                  ref: "refFormObserver",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var handleSubmit = ref.handleSubmit
                          return [
                            _c(
                              "b-form",
                              {
                                staticClass: "p-2",
                                on: {
                                  submit: function($event) {
                                    $event.preventDefault()
                                    return handleSubmit(_vm.onSubmit)
                                  },
                                  reset: function($event) {
                                    $event.preventDefault()
                                    return _vm.resetForm($event)
                                  }
                                }
                              },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "Title", rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(validationContext) {
                                          return [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: "Title",
                                                  "label-for": "task-title"
                                                }
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "task-title",
                                                    autofocus: "",
                                                    state: _vm.getValidationState(
                                                      validationContext
                                                    ),
                                                    trim: "",
                                                    placeholder: "Task Title"
                                                  },
                                                  model: {
                                                    value: _vm.taskLocal.title,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.taskLocal,
                                                        "title",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "taskLocal.title"
                                                  }
                                                }),
                                                _c("b-form-invalid-feedback", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        validationContext
                                                          .errors[0]
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Assignee",
                                      "label-for": "assignee"
                                    }
                                  },
                                  [
                                    _c("v-select", {
                                      staticClass: "assignee-selector",
                                      attrs: {
                                        dir: _vm.$store.state.appConfig.isRTL
                                          ? "rtl"
                                          : "ltr",
                                        options: _vm.assigneeOptions,
                                        label: "fullName",
                                        "input-id": "assignee"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "option",
                                            fn: function(ref) {
                                              var avatar = ref.avatar
                                              var fullName = ref.fullName
                                              return [
                                                _c("b-avatar", {
                                                  attrs: {
                                                    size: "26",
                                                    src: avatar
                                                  }
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "ml-50 d-inline-block align-middle"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " + _vm._s(fullName)
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "selected-option",
                                            fn: function(ref) {
                                              var avatar = ref.avatar
                                              var fullName = ref.fullName
                                              return [
                                                _c("b-avatar", {
                                                  attrs: {
                                                    size: "26",
                                                    src: avatar,
                                                    variant:
                                                      "light-" +
                                                      _vm.resolveAvatarVariant(
                                                        _vm.taskLocal.tags
                                                      ),
                                                    text: _vm.avatarText(
                                                      fullName
                                                    )
                                                  }
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "ml-50 d-inline-block align-middle"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " + _vm._s(fullName)
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.taskLocal.assignee,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.taskLocal,
                                            "assignee",
                                            $$v
                                          )
                                        },
                                        expression: "taskLocal.assignee"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Due Date",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(validationContext) {
                                          return [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: "Due Date",
                                                  "label-for": "due-date"
                                                }
                                              },
                                              [
                                                _c("flat-pickr", {
                                                  staticClass: "form-control",
                                                  model: {
                                                    value:
                                                      _vm.taskLocal.dueDate,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.taskLocal,
                                                        "dueDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "taskLocal.dueDate"
                                                  }
                                                }),
                                                _c(
                                                  "b-form-invalid-feedback",
                                                  {
                                                    attrs: {
                                                      state: _vm.getValidationState(
                                                        validationContext
                                                      )
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          validationContext
                                                            .errors[0]
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: "Tag", "label-for": "tag" }
                                  },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        dir: _vm.$store.state.appConfig.isRTL
                                          ? "rtl"
                                          : "ltr",
                                        multiple: "",
                                        "close-on-select": false,
                                        options: _vm.tagOptions,
                                        reduce: function(option) {
                                          return option.value
                                        },
                                        "input-id": "tags"
                                      },
                                      model: {
                                        value: _vm.taskLocal.tags,
                                        callback: function($$v) {
                                          _vm.$set(_vm.taskLocal, "tags", $$v)
                                        },
                                        expression: "taskLocal.tags"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Description",
                                      "label-for": "task-description"
                                    }
                                  },
                                  [
                                    _c("quill-editor", {
                                      staticClass: "border-bottom-0",
                                      attrs: {
                                        id: "quil-content",
                                        options: _vm.editorOption
                                      },
                                      model: {
                                        value: _vm.taskLocal.description,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.taskLocal,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression: "taskLocal.description"
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end border-top-0",
                                        attrs: { id: "quill-toolbar" }
                                      },
                                      [
                                        _c("button", {
                                          staticClass: "ql-bold"
                                        }),
                                        _c("button", {
                                          staticClass: "ql-italic"
                                        }),
                                        _c("button", {
                                          staticClass: "ql-underline"
                                        }),
                                        _c("button", {
                                          staticClass: "ql-align"
                                        }),
                                        _c("button", { staticClass: "ql-link" })
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex mt-2" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(255, 255, 255, 0.15)",
                                            expression:
                                              "'rgba(255, 255, 255, 0.15)'",
                                            modifiers: { "400": true }
                                          }
                                        ],
                                        staticClass: "mr-2",
                                        attrs: {
                                          variant: "primary",
                                          type: "submit"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.taskLocal.id
                                                ? "Update"
                                                : "Add "
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(186, 191, 199, 0.15)",
                                            expression:
                                              "'rgba(186, 191, 199, 0.15)'",
                                            modifiers: { "400": true }
                                          }
                                        ],
                                        attrs: {
                                          type: "reset",
                                          variant: "outline-secondary"
                                        }
                                      },
                                      [_vm._v(" Reset ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }